import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import { Button, Modal, ModalContent, ModalDescription, ModalActions, ModalHeader, Header,
         TableRow,TableHeaderCell,TableHeader,Checkbox,TableCell,TableBody,MenuItem,Icon,Label,Menu,Table
        } from 'semantic-ui-react';
import './volto-user-agreement.css';
import { getDataPolicyConsentInfo, agreeToDataPolicy, agreeNotYet } from './actions'
import { useLocation } from 'react-router-dom';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const UserAgreementDialog = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [open, setOpen] = React.useState(false)
  const [userSettings, setUserSettings] = useState({});


  const closeDialog = () => {
    dispatch(agreeNotYet()).then(
      setOpen(false)
    ).catch(function (err) {
      // some code that throws an error
      console.info(err)
      setOpen(false)
    });
  };
  const understandDialog = () => {
    if(isButtonDisabled){
      setButtonDisabled(false);
    }else{
      setButtonDisabled(true);
    };
  };
  const acceptDialog = () => {
    dispatch(agreeToDataPolicy()).then(
      setOpen(false)
    ).catch(function (err) {
      // some code that throws an error
      console.info(err)
      setOpen(false)
    });
  };

  useEffect(() => {
    if (userSettings['accept_data_policy'] === false){
      setOpen(true);
    }
  }, [userSettings]);

  useEffect(() => {
    // Your function to run on page change
    const handlePageChange = () => {
        const myCookieValue = getCookie('auth_token');
        // run when auth_token is present
        if (myCookieValue){
          dispatch(getDataPolicyConsentInfo()).then(
            result => setUserSettings(prevState =>({
              ...prevState,
              ...result
            }))
          ).catch(function (err) {
            // some code that throws an error
            console.info(err)
            setOpen(false)
          });
        }
    };
    handlePageChange();
  }, [location]);




  UserAgreementDialog.propTypes = {
  };

  return (
<Modal open={open}>
    <ModalHeader>Data Policy</ModalHeader>
    <ModalContent>
      <ModalDescription>
        <Header>Updated Data Policy Agreement</Header>
        <p>
        Hello {userSettings['fullname']},<br/><br/>&emsp;Recently the Ocean Tracking Network released an update to their Data Policy, 
        which allows for an embargo on sensitive tagged animal data, but no longer grants one by default. 
        If you agree that your projects should be bound by the
        <a href="https://members.oceantrack.org/data/policies/otn-data-policy_2022-2023-update.pdf/@@download/file"> new OTN Data Policy</a>, please click <b>"I Agree"</b>. 
        If you require embargoes for any of your projects' data, please submit an 
        <a href="https://members.oceantrack.org/data/data-collection/otn-data-embargo-request-form.docx/@@download/file"> embargo request </a> to OTNDC at your earliest convenience 
        and click <b>"Not yet"</b> below. For more detail, please review the 
        <a href="https://members.oceantrack.org/data/policies/otn-data-policy_2022-2023-update.pdf/@@download/file"> 2023 OTN Data Policy </a>
         or contact 
        <a href="mailto:otndc@dal.ca"> otndc@dal.ca</a> with questions.
        </p>
        {userSettings['projects'] != undefined ? (<><h4>Project Summary</h4><div style={{height: "240px", "overflow": "scroll"}}><Table celled striped>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Collectioncode</TableHeaderCell>
            <TableHeaderCell>Short Name</TableHeaderCell>
            <TableHeaderCell>Role</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
        {userSettings['projects'].map((project) => {
          return(
            <TableRow>
              <TableCell>{project['collectioncode']}</TableCell>
              <TableCell>{project['shortname']}</TableCell>
              <TableCell>{project['role']}</TableCell>
            </TableRow>
          )
        })}
        </TableBody>
        </Table></div></>) : null}
      </ModalDescription>
    </ModalContent>
    <ModalActions>
      <div className="fine_print">
      <b style={{"color": "red"}}> * </b>Please note that all principal investigators must agree to the new data policy in order for data to be released publicly.  Each collaborator must agree to the data policy individually - please answer this on behalf of yourself only.  Upon project-wide data policy agreement: animal detections and tagging metadata will be published to OTN's data endpoints, including all currently-held records and any future submissions.
      </div>
      <Checkbox label='I understand the Terms and Conditions' defaultChecked={false} onClick={() => understandDialog()}/>
      <Button className={isButtonDisabled ? "disabled" : ""}  onClick={() => acceptDialog()}
        color="green">I Agree < i className="ri-check-line"></i> 
      </Button>
      <Button color='black' onClick={() => closeDialog()}>
        Not yet
      </Button>
    </ModalActions>
  </Modal>
  );
};

export default UserAgreementDialog;
