import { GET_USER_PROJECTS, GET_FOLDER_CONTENT, GET_NODES } from './constants';

export function getUserProjects(url) {
  return {
    type: GET_USER_PROJECTS,
    request: {
      op: 'get',
      path: `${url}/project_root.json`,
    },
  };
}

export function getFolderContent(url, id){
  return {
    type: GET_FOLDER_CONTENT,
    request: {
      op: 'get',
      path: `${url}/project_child.json?id=${id}`,
    }
  };
}

export function getNodes(){
  return {
    type: GET_NODES,
    request: {
      op: 'get',
      path: '/@controlpanels/userprojects-controlpanel',
    }
  };
}
